<template lang="pug">
  .permission-modal__sidebar-layout
    h2.card-subtitle.permission-modal__sidebar-title {{ 'base.section' | translate }}
    .permission-modal__sidebar
      ul.permission-modal__list
        template(v-if="loaded")
          li.permission-modal__item(
            v-for="section in permissionSections"
            @click="fetchSection(section)"
            :key="section.id"
            :class="{ 'permission-modal__item--active': section === activeSection }")
            | {{ sections[section.sections_key] | translate }}
        template(v-else)
          .permission-modal__item-skeleton(v-for="i in 11" :key="i")

</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { PERMISSION_SECTIONS } from '@/util/consts.js'

export default {
  name: 'PermissionModalSidebar',

  async mounted () {
    await this.fetchPermissionSections()
    this.activeSection = this.permissionSections[0]
    this.loaded = true
  },

  data: () => ({
    activeSection: null,
    loaded: false,
    sections: PERMISSION_SECTIONS
  }),

  methods: {
    ...mapActions(['fetchPermissionSections']),

    async fetchSection (section) {
      this.activeSection = section
      await this.$store.dispatch('fetchPermissionSectionById', { id: this.activeSection.id })
    }
  },

  computed: {
    ...mapGetters(['permissionSections'])
  }
}
</script>

<style lang="scss" scoped>
  @import "@/sass/abstracts/_variables.scss";
  @import "@/sass/abstracts/_mixins.scss";

  .permission-modal__item-skeleton {
    height: 2rem;
    border-radius: .8rem;
    margin-bottom: 2.7rem;
    width: 80%;

    &:last-child {
      margin-bottom: 0;
    }

    @include skeleton;
  }
</style>
